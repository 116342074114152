<template>
  <div class="classList">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入班级名称"></el-input>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <el-button type="primary">
          <router-link to="/class/classDetails" class="goto">新增班级</router-link>
        </el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="class_name" label="班级名称" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="class_cover" label="班级介绍" show-overflow-tooltip width="500"></el-table-column>
        <el-table-column prop="created" label="开班时间"></el-table-column>
        <el-table-column prop="count" label="学生人数" width="100"></el-table-column>
        <el-table-column prop="chain_name" label="所属机构" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getClassList, shopDelClass } from '../../../request/api'
import MyPagination from '../../../components/MyPagination.vue'

export default {
  inject: ['reload'],
  components: {
    MyPagination
  },
  data () {
    return {
      input: '',
      tableData: [],
      query_info: {
        chain_id: '',
        limit: 10,
        page: 1
      },
      total: 0,
      loading: false
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async getClasslist () {
      try {
        this.loading = true
        const res = await getClassList(this.query_info.chain_id, this.query_info.limit, this.query_info.page, this.input)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }

        this.tableData = res.data.data.classList
        this.total = res.data.data.totalNumber
      } finally {
        this.loading = false
      }
    },
    // 搜索按钮
    searchInp () {
      this.query_info.page = 1
      this.tableData = []
      this.getClasslist()
    },
    // 编辑操作
    handleEdit (row) {
      this.$router.push(
        {
          path: '/class/classDetails',
          query: {
            shop_class_id: row.shop_class_id
          }
        }
      )
    },
    // 删除操作
    handleDelete (row) {
      this.$confirm('是否删除该班级？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let postData = {
          shop_class_id: row.shop_class_id
        }
        const res = await shopDelClass(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '删除成功！'
        })
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.tableData = []
      this.getClasslist()
    }
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  },
  mounted () {
    this.getClasslist()
  }
}
</script>

<style lang="less" scoped>
.classList {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 20px;
      .goto {
        color: #fff;
      }
    }
  }
  .wrapper {
    margin: 20px 0;
  }
}
</style>